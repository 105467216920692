import Router from 'next/router';
import React from 'react';
import withGA from 'next-ga';
import App, { AppInitialProps, AppContext } from 'next/app';
import * as Sentry from '@sentry/node';

Sentry.init({
  enabled: process.env.SENTRY_ENVIRONMENT !== 'localhost',
  dsn: process.env.SENTRY_DSN,
  environment: process.env.SENTRY_ENVIRONMENT,
});

class MyApp extends App<{ err: Error }> {
  static async getInitialProps({ Component, ctx }: AppContext): Promise<AppInitialProps> {
    let pageProps = {};
    if (Component.getInitialProps) {
      pageProps = await Component.getInitialProps(ctx);
    }
    return { pageProps };
  }

  render() {
    const { Component, pageProps } = this.props;

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const { err } = this.props;
    const modifiedPageProps = { ...pageProps, err };

    return (
      <Component {...modifiedPageProps} />
    );
  }
}

export default withGA(process.env.analytics, Router)(MyApp);
